import React from 'react'

import BlogPosts from '~/components/BlogPosts'
import Layout from '~/components/layouts'
import MainHero from '~/components/MainHero'
import Seo from '~/components/Seo'
import TranslatedOptions from '~/components/TranslatedOptions'
import useBlogMetadata from '~/staticQueries/useBlogMetadata'
import { HOME_TRANSLATED_OPTIONS } from '~/utils/useI18N'

const MainPage = ({ pageContext: { availableLanguages, lang, link, posts } }) => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useBlogMetadata()

  return (
    <>
      <Seo lang={lang} link={link} />
      <Layout includeNavBar={false} lang={lang}>
        <MainHero title={title} />
        <BlogPosts data={posts} showAuthor />
        <TranslatedOptions
          availableLanguages={availableLanguages}
          descriptionKey={HOME_TRANSLATED_OPTIONS}
          marginTop
          showContainer
        />
      </Layout>
    </>
  )
}

export default MainPage
